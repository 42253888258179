import { request } from "@/util/request";
import { message } from "ant-design-vue";

// 获取信息列表
export async function getlist(params) {
    const url = `/boss/marketing/coupon`;
    const res = await request.get(url,{
        params
    });
    if (res.status == 200 && res.data){
        return res.data
    }else{
        console.log("请求失败");
        return {
            current: 1,
            detail: [],
            last: 0,
            limit: 1,
            total: 1,
        };
        
    }
}
export async function getPointsRule() {
    const url = '/boss/operation/newbiegift';
    const res = await request.get(url);
    if (res.status == 200) {
        return res.data;
    } else {
        return undefined;
    }
}

export async function postlist(params) {
    const url = '/boss/operation/newbiegift';
    const res = await request.post(url, { ...params });
    if (res.status == 200 && res.data == 1) {
        message.success("保存成功")
        return { params };
    } else {
        return false;
    }
}

